<template>
  <div class="line-text" style="margin-bottom: 50px">
    江苏宁清信息科技有限公司以大气移动监测和工业园区监测需求发展为导向,通过引进国内外知名产品以及国内专家，构建大气环境领域专业经营团队,实现业务设计创新与服务能力升级，从而不断加强公司在国内的企业价值。公司核心围绕环保部门痛点需求，构建化工园区监测和服务咨询业务协同发展的业务体系，创造顶级口碑的个
    性化服务成为公司发展的企业标签。
  </div>

  <div class="sub-title">核心优势</div>
  <div
    class="line-text"
    style="letter-spacing: 1.5px; margin: 10px auto 40px auto"
  >
    依托大气监移动监测集成平台和具备核心竞争力的走航监测设备，宁清信息科技通过嫁接高校优势资源，立足于做专业的光化学溯源成因分析。
  </div>

  <div class="sub-title">阶段成果</div>
  <img
    src="~assets\image\company_detail\宁清1.png"
    alt="和云科技"
    style="margin: 40px auto 0px auto"
  />
</template>
