<template>
  <div class="line-text">
    南京和云科技有限公司主要从事以数据集成分析为手段的大气超级站数据综合应用、项目开发及技术、咨询服务。从多站点网格化的面上多类型大数据整合，基于大气组分监测数据的污染成因分析，到大气超站数据集成平台的开发，和云科技深耕以数据为本的大气污染综
    合解析业务，以基于监测数据对大气污染关键过程、关键控制因素的深刻理解为客户提供的快速解决方案。
  </div>
  <img
    src="~assets\image\company_detail\和云1.png"
    alt="和云科技"
    style="margin: 40px auto"
  />
  <div class="sub-title">核心优势</div>
  <div
    class="line-text"
    style="letter-spacing: 1.5px; margin: 10px auto 40px auto"
  >
    依托大气超站观测集成平台和独有算法，和云科技可以很便捷对多个不同类型的大气环境超级站数据集成、质量控制、来源解析、过程分析、数据展示、报告管理等。
  </div>
  <div class="sub-title">主营产品</div>

  <img
    src="~assets\image\company_detail\和云2.png"
    alt="和云科技"
    style="margin: 20px auto 0px auto"
  />
</template>
