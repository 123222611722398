<template>
  <div class="line-text" style="margin-bottom: 50px">
    南京叁云科技有限公司是南京浦蓝大气环境与绿色发展研究院在气象服务方向的重点孵化企业。主要从事气象多领域服务，采用最先进的人工智能机器学习技术与传统气象数值模式预报相结合的方法，提供客观化、网格化、精细化的气象预报产品，为用户提供定制化的气象数据处理研发和气象预报技术
    研发服务。并通过大数据挖掘结合细分行业领域内在需求，通过气象与行业大数据融合为各类用户提供基于行业影响的的专业气象咨询服务产品。
  </div>

  <div class="sub-title">核心优势</div>
  <div
    class="line-text"
    style="letter-spacing: 1.5px; margin: 10px auto 40px auto"
  >
    依托整体高素质人才研发团队，叁云科技自主创新，采用人工智能与传统气象预报信息相结合的方式，核心团队人员拥有深厚的专业气象背景，同时在人工智能机器学习领域拥有丰富的开发经验，自主创新研发的多项预报技术目前已经达到国际前沿水平。
  </div>
  <img
    src="~assets\image\company_detail\三云1.png"
    alt="三云"
    style="margin: 20px auto 0px auto; width: 50%"
  />
  <div class="sub-title">阶段成果</div>
  <img
    src="~assets\image\company_detail\三云2.png"
    alt="和云科技"
    style="margin: 20px auto 0px auto"
  />
</template>
