<template>
  <div class="line-text">
    南京朗世科技有限责任公司是大气臭氧污染有、着从监测到分析方面的丰富经验，是研究院在臭氧污染源解析方向的重要孵化对象。
  </div>

  <div class="sub-title" style="margin: 50px auto 0px auto">产品优势</div>
  <div class="line-text" style="margin: 10px auto 40px auto">
    1.ppt级大气挥发性有机化合物分析技术<br />
    2.包括PMF、CMB、PCA-APCS等在内的挥发性有机 化合物源解析技术<br />
    3.基于MCM、CB05等化学反应机制构建的大气光<br />
    化学反应模式，对臭氧生成过程、臭氧生成敏 感性、臭氧来源进行模拟分析<br />
    4.精确到排放源的，并考虑实施可行性的臭氧控制方案制定<br />
    5.基于有机气溶胶分子标识物的高时间分辨率监 测开展的大气有机气溶胶源解析<br />
    6.综合考虑气态污染物、颗粒态污染物和微生物生理毒性的室内空气质量评估<br />
  </div>

  <img
    src="~assets\image\company_detail\朗世.png"
    alt="朗世"
    style="margin: 20px auto 0px auto"
  />
</template>
