<template>
  <div class="line-text" style="margin-bottom: 50px">
    南京山水云天生态技术有限公司依托中国科学院西北生态环境资源研究院、南京大学、河海大学等国内顶级科研院校，在生态环境资源调查与评价、环境质量监测、水文水资源、水质预警预报等方向开展技术研发与产业化应用，积累了丰硕的数据观测结果与技术沉淀。<br />
    基于多源卫星遥感观测、物联网在线感知、区块链、大数据集成与分析技术，面向环境要素质量与自然生态综合评估、未来预警，构建美丽中国评价体系、开展区域量化综合评价。广泛支撑生态环境、自然资源、国家公园、自然保护区等相关管理部门业务工作，携手共建“天蓝、地绿、水清、人和”美丽中国。
  </div>

  <div class="sub-title">产品介绍</div>

  <img
    src="~assets\image\company_detail\山水云天.png"
    alt="山水云天"
    style="margin: 20px auto 0px auto"
  />
</template>
