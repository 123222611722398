<template>
  <div class="banner" />
  <div class="incubate-box">
    <point-title :title="companyName" />
    <div class="company-detail">
      <component :is="companyName" />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import companyList from "./companyList.js";
import 南京和云科技有限公司 from "./Company.vue/HeYun.vue";
import 南京叁云科技有限公司 from "./Company.vue/SanYun.vue";
import 江苏宁清信息科技有限公司 from "./Company.vue/NingQing.vue";
import 南京山水云天生态技术有限公司 from "./Company.vue/ShanShuiYunTian.vue";
import 南京朗世科技有限责任公司 from "./Company.vue/LangShi.vue";
export default {
  name: "CompanyDetail",
  components: {
    南京和云科技有限公司,
    南京叁云科技有限公司,
    江苏宁清信息科技有限公司,
    南京山水云天生态技术有限公司,
    南京朗世科技有限责任公司,
  },
  setup() {
    const route = useRoute();
    const companyName = computed(
      () => companyList.find((item, index) => route.query.id == index)?.name
    );

    return { companyList, companyName };
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/page/company_detail.scss";
</style>
